import AmountColumn from './partials/partialsTable/AmountColumn.component';
import CreationColumn from './partials/partialsTable/CreationColumn.component';
import FeeColumn from './partials/partialsTable/FeeColumn.component';
import OrderIdColumn from './partials/partialsTable/OrderIdColumn.component';
import StatusColumn from './partials/partialsTable/StatusColumn.componen';

import { ReactComponent as Created } from 'src/images/advPlanRequestsStatus/created.svg';
import { ReactComponent as Approved } from 'src/images/advPlanRequestsStatus/approved.svg';
import { ReactComponent as Unsuitable } from 'src/images/advPlanRequestsStatus/unsuitable.svg';
import { ReactComponent as Expired } from 'src/images/advPlanRequestsStatus/expired.svg';

export const advPlanRequestsTableColumnConfig = [
  {
    id: 'order-id',
    header: 'advPlanRequestsHeaderTable.OrderId',
    RenderComponent: OrderIdColumn,
    rowCellConfig: {
      w: '100%',
      minWDesktop: '37.4rem',
    },
  },
  {
    id: 'creation',
    header: 'advPlanRequestsHeaderTable.Creation',
    RenderComponent: CreationColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'amount',
    header: 'advPlanRequestsHeaderTable.Amount',
    RenderComponent: AmountColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'fee',
    header: 'advPlanRequestsHeaderTable.Fee',
    RenderComponent: FeeColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
  {
    id: 'status',
    header: 'advPlanRequestsHeaderTable.Status',
    RenderComponent: StatusColumn,
    rowCellConfig: {
      w: '100%',
    },
  },
];

export enum AdvPlanRequestsStatus {
  created = 0,
  performed = 1,
  unsuitable = 2,
  expired = 3,
  approved = 4,
  processing = 5,
}

export const AdvPlanRequestsStatusMap = {
  [AdvPlanRequestsStatus.created]: {
    label: 'advPlanRequestStatus.created',
    icon: Created,
    style: {
      bg: '#636E82',
      color: '#fff',
      colorBorder: '#636E82',
    },
  },
  [AdvPlanRequestsStatus.approved]: {
    label: 'advPlanRequestStatus.approved',
    icon: Approved,
    style: {
      bg: '#009E6B',
      color: '#fff',
      colorBorder: '#009E6B',
    },
  },
  [AdvPlanRequestsStatus.performed]: {
    label: 'advPlanRequestStatus.approved',
    icon: Approved,
    style: {
      bg: '#009E6B',
      color: '#fff',
      colorBorder: '#009E6B',
    },
  },
  [AdvPlanRequestsStatus.unsuitable]: {
    label: 'advPlanRequestStatus.unsuitable',
    icon: Unsuitable,
    style: {
      bg: '#F13D3E',
      color: '#fff',
      colorBorder: '#F13D3E',
    },
  },
  [AdvPlanRequestsStatus.expired]: {
    label: 'advPlanRequestStatus.expired',
    icon: Expired,
    style: {
      bg: '#FFAD0C',
      color: '#35302F',
      colorBorder: '#FFAD0C',
    },
  },
  [AdvPlanRequestsStatus.processing]: {
    label: 'lbl.processing',
    icon: Created,
    style: {
      bg: '#636E82',
      color: '#fff',
      colorBorder: '#636E82',
    },
  },
};
