import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import {
  IncomeStatusMap,
  PayByLinkStatus,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import React, { useState } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { Navigate, useOutletContext } from 'react-router-dom';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { H4, P, PNote, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import {
  BulletText,
  ContainerWrapper,
} from '../style/PayByLinkDetailInfo.style';
import ServiceCostModalLodable from './ServiceCostModal/ServiceCostModal.lodable';
import {
  feesForOrderData,
  feesForOrderMethodApi,
} from 'src/services/feesForOrder/feesForOrder.request';
import { useMutation } from 'react-query';
import Loader from 'src/components/Loader/Loader.component';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import { InstallmentsRecapStatusComponent } from 'src/components/InstallmentsRecapStatus/InstallmentsRecapStatus.component';

function getStatusColor(x: number) {
  switch (x) {
    case 0:
      return colors.greyBorder;
    case 1:
      return colors.greenStatus;
    case 3:
      return colors.redStatus;
    case 4:
      return colors.white;
    default:
      return colors.redStatus;
  }
}

function getStatusBorderColor(x: number) {
  switch (x) {
    case 0:
      return colors.greyBorder;
    case 1:
      return colors.greenStatus;
    case 3:
      return colors.redStatus;
    case 4:
      return colors.greenStatus;
    default:
      return colors.redStatus;
  }
}

function LinkInfoTab2() {
  const dataLink = useOutletContext<PayByLinkDetailsContext>();

  const { Incomes, OrderGuid, FlagCredit, Status } = dataLink;
  const [modalData, setModalData] = useState<any>();
  const [amount, setAmount] = useState(0);

  const {
    formatters: { formatCurrency },
  } = useI18n();

  const progress = {
    green: dataLink.IncomeRatios.PaidRatio,
    red: dataLink.IncomeRatios.OverdueRatio,
    white: dataLink.IncomeRatios.ProcessingRatio,
  };

  const [modalOpen, setModalOpen] = useState(false);
  const { mutate, isLoading, isError } = useMutation(
    (values: feesForOrderData) => feesForOrderMethodApi(values),
    {
      onSuccess: (res) => setModalData(res?.data?.ResultSet),
    },
  );

  const handleClick = (orderGuid: string, iNo: number, amount: number) => {
    mutate({ orderGuid, iNo });
    setAmount(amount);
    setModalOpen(true);
  };

  if (isError) {
    return <Navigate to="/oops" />;
  }
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <ContainerWrapper>
      <PNote marginBottom="0.8rem" textAlign="center">
        <Translate id="text.amountServiceCost" />
      </PNote>

      <InstallmentsRecapStatusComponent
        progressBarProps={{ progress }}
        installmentRatios={dataLink.IncomeRatios}
      />

      {Incomes.map((income, i) => {
        if (
          [PayByLinkStatus.cancelledLink, PayByLinkStatus.interrupted].includes(
            Status,
          ) &&
          income.Status === InstallmentStatus.toPay
        ) {
          income.Status = PayByLinkStatus.cancelledLink;
        }
        const { label, icon, color } =
          IncomeStatusMap[income.Status as InstallmentStatus];
        return (
          <div key={i}>
            <div
              style={{
                padding: window.innerWidth < 1024 ? '1.2rem' : '1.6rem',
              }}
            >
              <PSmall marginBottom="0.4rem">
                {refactorFormatDate(income.Expiration, 'DD MMMM YYYY')}
              </PSmall>
              <Flex justify="space-between" marginBottom="0.8rem">
                <Flex>
                  {[
                    PayByLinkStatus.cancelledLink,
                    PayByLinkStatus.interrupted,
                  ].includes(Status) ? (
                    <P bold colorBlack>
                      <Translate
                        id={
                          [
                            PayByLinkStatus.cancelledLink,
                            PayByLinkStatus.interrupted,
                          ].includes(Status)
                            ? 'lbl.rateNumber'
                            : income.Advance
                              ? 'lbl.incomeAdvNumber'
                              : 'lbl.incomeNumber'
                        }
                      />
                      {income.Number}
                    </P>
                  ) : (
                    <BulletText
                      align="center"
                      innerColor={getStatusColor(income.Status)}
                      borderColor={getStatusBorderColor(income.Status)}
                    >
                      <P bold colorBlack>
                        <Translate
                          id={
                            [
                              PayByLinkStatus.cancelledLink,
                              PayByLinkStatus.interrupted,
                            ].includes(Status)
                              ? 'lbl.rateNumber'
                              : income.Advance
                                ? 'lbl.incomeAdvNumber'
                                : 'lbl.incomeNumber'
                          }
                        />
                        {income.Number}
                      </P>
                    </BulletText>
                  )}
                </Flex>

                <Flex
                  justify="center"
                  align="center"
                  style={{ color: colors.textColorGrey }}
                >
                  <PSmall>
                    <Translate id={label} />
                  </PSmall>
                  {icon && (
                    <Icon
                      iconSize="2rem"
                      iconHeight="2rem"
                      marginLeft="0.6rem"
                      color={color}
                      svgIconComponent={icon}
                    />
                  )}
                </Flex>
              </Flex>

              <Flex justify="space-between" align="center">
                <H4
                  style={{
                    padding: '0.4rem',
                    borderRadius: '1rem',
                    backgroundColor: colors.backgroundBlue,
                    width: 'fit-content',
                  }}
                >
                  {formatCurrency(income.Amount)}
                </H4>

                <Button
                  variant="LinkPrimary"
                  onClick={() =>
                    handleClick(OrderGuid, income.Number, income.Amount)
                  }
                  padding="0"
                >
                  <PSmall
                    textColor={colors.lightBlueBorder}
                    style={{
                      textDecoration: 'underline',
                      fontSize: '1.2rem',
                      fontWeight: '400',
                      lineHeight: '2rem',
                    }}
                  >
                    <u>
                      <Translate id="text.netServiceCosts" />
                    </u>
                  </PSmall>
                </Button>
              </Flex>
            </div>
            {i + 1 < Incomes.length && <hr />}
          </div>
        );
      })}
      {modalOpen && (
        <ServiceCostModalLodable
          buttonTitle="text.okUnderstand"
          modalTitle="text.serviceCost"
          modalData={modalData}
          amount={amount}
          i18n
          flagCredit={FlagCredit}
          handleClose={() => setModalOpen(false)}
        />
      )}
    </ContainerWrapper>
  );
}

export default LinkInfoTab2;
