import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import type { Installment } from 'src/services/installments/installments.types';

interface Income {
  Expiration: string;
  Advance: boolean;
  Number: number;
  Status: number;
  Amount: number;
  FeeAmount: number;
  Currency: string;
}

interface InstallmentDetails {
  Expiration: string;
  Number: number;
  Status: InstallmentStatus;
  Amount: number;
  Currency: string;
  Fees: number;
  Holders: { Holder: string; Amount: number }[];
}

export type IncomeRatios = {
  Total: number;
  Paid: number;
  PaidRatio: number;
  PaidAmount: number;
  Unpaid: number;
  UnpaidRatio: number;
  UnpaidAmount: number;
  Overdue: number;
  OverdueRatio: number;
  OverdueAmount: number;
  Processing: number;
  ProcessingAmount: number;
  ProcessingRatio: number;
};

export enum InstallmentStatus {
  toPay,
  paid,
  expired = 3,
  processing,
  cancelled,
}

export interface OrderDetails {
  OrderId: number;
  OrderGuid: string;
  Created: string;
  Expiration: string;
  LastUpdate?: string;
  Amount: number;
  FeeAmount: number;
  Currency: string;
  Status: StatusPyByLinkValues;
  Description: string;
  BuyerName: string;
  BuyerEmail: string;
  PayByLinkUrl: string;
  FlagCredit: boolean;
  CanBeRenewed: boolean;
  CanBeCanceled: boolean;
  InstallmentCount: number;
  Underwriting: string;
  IncomeRatios: IncomeRatios;
  EditPlanStatus: OrderEditPlanStatuses;
  EditPlanAmount: number;
  EditStartINo: number;
  PluginExternalId: number;
  Incomes: Income[];
  Installments: InstallmentDetails[];
  InviteEmailsSent: InviteEmailsSent[];
  LastInstallmentDate: string;
  CanPayWithSDD: boolean;
  CanEvaluateBuyer: boolean;
  UnderwritingDate: string;
  CanEditPlan: string;
  EditPlanType?: 'shipping_date' | 'default';
}

export const enum OrderEditPlanStatuses {
  available = 'available',
  waiting = 'waiting',
  unsuitable = 'unsuitable',
}

export interface getOrderDetailsResponse {
  ResultSet: OrderDetails;
}

interface InviteEmailsSent {
  Recipient: string;
  SentDate: string;
}

export type BuyerOrderStatus = 1 | 2 | 4 | 8 | 9;

export interface InstallmentRatios {
  Total: number;
  Paid: number;
  PaidRatio: number;
  PaidAmount: number;
  Unpaid: number;
  UnpaidRatio: number;
  UnpaidAmount: number;
  Overdue: number;
  OverdueRatio: number;
  OverdueAmount: number;
  Processing: number;
  ProcessingAmount: number;
  ProcessingRatio: number;
}

export interface GetOrderBuyerDetails {
  ResultSet: {
    Id: number;
    Guid: string;
    Description: string;
    Amount: number;
    Currency: string;
    Status: BuyerOrderStatus;
    MerchantName: string;
    PaymentMethod: PaymentMethodResult;
    BackupPaymentMethod?: PaymentMethodResult | null;
    InstallmentRatios: InstallmentRatios;
    Installments: Installment[];
    Created: string;
    CanPayWithSDD: boolean;
  };
}

export type Order = {
  OrderId: number;
  OrderGuid: string;
  Status: 0 | 1 | 3 | 9;
  Created: string;
  Amount: number;
  Currency: string;
  MerchantName: string;
  InstallmentRatios: InstallmentRatios;
};

export interface GetOrdersBuyer {
  ResultSet: {
    OrdersRegularCount: number;
    OrdersNotRegularCount: number;
    OrdersCompleteCount: number;
    OrdersRegular: Order[];
    OrdersNotRegular: Order[];
    OrdersComplete: Order[];
    OrdersAmount: number;
    OrdersCount: number;
    OrdersInterrupted: Order[];
    OrdersInterruptedCount: number;
  };
}

export type Merchant = {
  Id: number;
  Name: string;
};
export type GetOrdersBuyerInitialDataResponse = {
  ResultSet: {
    Merchants: Merchant[];
  };
};
