import Icon from 'src/components/Icon/Icon.component';
import moment from 'moment';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StepperProps } from './Stepper.types';
import { CircularProgressSmall, FlexWrapperList } from './style/stepper.style';
import { StepperRemodulationArray } from './Stepper.config';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ReactComponent as Arrow } from 'src/images/icons/arrow_back_right.svg';

const StepperRemodulationByDate: React.FC<StepperProps> = ({
  currentDate,
  dataStepper,
  onlyToPay,
  numInstallmentToShow,
}) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const arrayInstallments = onlyToPay
    ? dataStepper?.filter(
        (item) => numInstallmentToShow && item.Number >= numInstallmentToShow,
      )
    : dataStepper;

  return (
    <>
      <FlexWrapperList
        gap="1.6rem"
        align="center"
        flexDirection="row"
        className="items-center justify-between p-10"
      >
        <div className="flex-1 flex-grow text-right">
          <P bold>
            <Translate id="text.shipping" />
          </P>
        </div>

        <div className="flex w-24 justify-center">
          <Icon iconSize="4rem" svgIconComponent={Arrow} />
        </div>

        <div className="flex-1 flex-grow text-left">
          <PSmall bold style={{ color: colors.textColorGrey }}>
            {currentDate ? (
              moment().isSame(currentDate, 'day') ? (
                <Translate id="text.today" />
              ) : (
                refactorFormatDate(currentDate, 'DD MMMM YYYY')
              )
            ) : (
              '-'
            )}
          </PSmall>
        </div>
      </FlexWrapperList>
      {arrayInstallments?.map((item, i) => {
        const { outlineColor } =
          StepperRemodulationArray[item.Status as InstallmentStatus];
        const date = currentDate
          ? moment(currentDate)
              .add(30 * (i + 1), 'day')
              .toString()
          : null;
        return (
          <>
            <PSmall className="py-2 text-center capitalize">
              <Translate id="text.afterThirtyDays" />
            </PSmall>
            <FlexWrapperList
              background
              gap="1.6rem"
              align="center"
              flexDirection="row"
              key={i}
              className="items-center justify-between p-10"
            >
              <div className="flex-1">
                <Flex className="items-center">
                  <CircularProgressSmall
                    outlineColor={outlineColor}
                    colorGrey={
                      item.Status === InstallmentStatus.paid || onlyToPay
                    }
                  >
                    {onlyToPay ? item?.Number : i + 1}
                  </CircularProgressSmall>
                  <P bold>{formatCurrency(item.Amount)}</P>
                </Flex>
              </div>

              <div className="flex w-24 justify-center">
                <Icon iconSize="4rem" svgIconComponent={Arrow} />
              </div>

              <div className="flex-1 text-right">
                <P bold style={{ color: colors.textColorBlack }}>
                  {date ? refactorFormatDate(date, 'DD MMMM YYYY') : '-'}
                </P>
              </div>
            </FlexWrapperList>
          </>
        );
      })}
    </>
  );
};

export default StepperRemodulationByDate;
