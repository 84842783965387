import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as NoLink } from 'src/images/icons/adv_request_icon.svg';
import { ReactComponent as NoSigned } from 'src/images/icons/adv_request_no_signed.svg';
import { ReactComponent as Bill } from 'src/images/icons/bill.svg';
import {
  advOutletContext,
  SelectedItem,
} from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { invoiceDateField } from 'src/services/orderLink/orderLink.config';
import { updateInvoiceDateApi } from 'src/services/orderLink/orderLink.request';
import {
  EligibleOrder,
  updateInvoiceDateData,
} from 'src/services/orderLink/orderLink.types';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import ChooseLinkCard from './ChooseLinkCard.component';
import {
  ButtonContainerCustom,
  CardGridContainer,
  ResponsiveContainerCustom,
} from './ChooseLinkPage.style';
import NoLinkMessage from './NoLinkMessage.component';
import { formConfigLinkInvoiceDate } from './partials/InvoiceDateModal/InvoiceDateModal.helpers';
import InvoiceDateModalLodable from './partials/InvoiceDateModal/InvoiceDateModal.lodable';
import { FormStyle } from 'src/style/styleInput.style';
import BuyersPopover from './partials/popover/buyers/BuyersPopover.component';
import PeriodPopover from './partials/popover/period/PeriodPopover.component';
import FilteredListEligibleOrder from './partials/filters/FilteredList/FilteredListEligibleOrder.component';
import {
  DataFormEligibleOrders,
  searchCriteriaFormConfig,
} from './partials/SearchCriteriaForm.helpers';
import { DataFormPayByLink } from '../../../payByLinkPage/partials/SearchCriteriaForm.helpers';
import { AlertComponent } from '../../../../components/ORION/Alert/Alert.component';

function ChooseLinkPage() {
  const canGiveCredit = useProfileStore((state) => state.CanGiveCredit);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    dataLinks,
    setSelectedLinks,
    selectedLinks,
    filtersLinks,
    setFiltersLinks,
    clients,
  } = useOutletContext<advOutletContext>();
  const [selectedLink, setSelectedLink] = useState<EligibleOrder>();
  const navigate = useNavigate();
  const orderInvoiceRange = useProfileStore((state) => state.OrderInvoiceRange);

  const {
    formatters: { formatCurrency },
  } = useI18n();
  const { initialValues, resolver } = formConfigLinkInvoiceDate(
    selectedLink?.OrderCreationDate,
    orderInvoiceRange.DaysBefore,
    orderInvoiceRange.DaysAfter,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { initialValues: initialValuesFilters, resolver: resolverFilter } =
    searchCriteriaFormConfig();

  const methodsFilters = useForm({
    defaultValues: initialValuesFilters,
    resolver: resolverFilter,
    mode: 'onChange',
  });

  const { mutate, isLoading: isLoadingInvoiceDate } = useMutation(
    (values: updateInvoiceDateData) => updateInvoiceDateApi(values),
    {
      onSuccess: (res) => {
        const selectedItem: SelectedItem = {
          OrderId: selectedLink?.OrderId,
          BuyerId: selectedLink?.BuyerId,
          GivableAmount: selectedLink?.AmountGivable,
          AmountAlreadyGave: selectedLink?.AmountAlreadyGave,
          HasAlreadyGave: selectedLink?.HasAlreadyGave,
        };
        if (
          selectedLinks?.findIndex(
            (item) => item.OrderId === selectedLink?.OrderId,
          ) !== -1
        ) {
          const updatedLinks = selectedLinks?.map((prevItem) => {
            if (prevItem.OrderId === selectedItem.OrderId) {
              selectedItem.INo = prevItem.INo;
              return selectedItem;
            }
            return prevItem;
          });
          setSelectedLinks(updatedLinks);
          return;
        }
        setSelectedLinks((prevLinks: SelectedItem[]) => [
          ...prevLinks,
          { ...selectedItem, INo: prevLinks.length + 1 },
        ]);
        setModalOpen(false);
      },
      onError: () => navigate(RoutePath.oops),
    },
  );

  const amountSelectedLinks = selectedLinks?.reduce(
    (acc, currentItem) => (acc ?? 0) + (currentItem?.GivableAmount ?? 0),
    0,
  ) as number;

  const SelectedIds: number[] = [];
  selectedLinks?.map((item) => SelectedIds.push(item.OrderId!));
  const numLinks = SelectedIds.length;

  const onSubmit = () => {
    mutate({
      OrderId: selectedLink?.OrderId,
      InvoiceDate: methods.getValues()[invoiceDateField],
    });
    methods.reset();
  };
  const selectLinkHandler = (link: EligibleOrder) => {
    setSelectedLink(link);
    if (!link.InvoiceDate) {
      setModalOpen(true);
      return;
    }
    setSelectedLinks((prevLinks: SelectedItem[]) => [
      ...prevLinks,
      {
        INo: prevLinks.length + 1,
        OrderId: link.OrderId,
        BuyerId: link.BuyerId,
        GivableAmount: link.AmountGivable,
        AmountAlreadyGave: link.AmountAlreadyGave,
        HasAlreadyGave: link.HasAlreadyGave,
      },
    ]);
  };
  const removeLinkHandler = (orderId: number) => {
    setSelectedLinks((prevLinks: SelectedItem[]) =>
      prevLinks
        .filter((link) => link.OrderId !== orderId)
        .map((link, index) => ({
          ...link,
          INo: index + 1,
        })),
    );
  };
  const editInvoiceDate = (link: EligibleOrder) => {
    setSelectedLink(link);
    methods.setValue(
      invoiceDateField,
      refactorFormatDate(link.InvoiceDate, 'yyyy-MM-DD'),
    );
    setModalOpen(true);
  };

  const onCloseHandlerFilter = () => {
    Object.keys(filtersLinks).map((field) =>
      methodsFilters.setValue(
        field,
        filtersLinks[field as keyof DataFormEligibleOrders],
      ),
    );
  };

  const onSubmitFilter = async (values: DataFormPayByLink) => {
    setFiltersLinks({ ...values });
    if (selectedLinks && selectedLinks.length > 0) {
      setSelectedLinks([]);
    }
  };

  useEffect(() => {
    if (Object.keys(filtersLinks).length > 0) {
      Object.keys({ ...filtersLinks }).map((field) => {
        return methodsFilters.setValue(
          field,
          { ...filtersLinks }[field as keyof DataFormEligibleOrders],
        );
      });
    }
  }, []);

  const linksCount = dataLinks.length ?? 0;
  const noLink = linksCount < 1 && canGiveCredit;
  const noValidLink = dataLinks.length < 1 && linksCount > 0 && canGiveCredit;

  if (isLoadingInvoiceDate)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return (
    <>
      <div className="bg-white">
        <ResponsiveContainer>
          <Flex padding="3.2rem 0" flexDirection="column">
            <TextDefaultPage
              title="lbl.requestAdvPaymentTitle"
              subTitle="text.requestAdvPaymentDescription"
            />
            {numLinks > 0 && (
              <>
                <AlertComponent
                  variant="default"
                  titleLbl="text.recalculatedAmounts"
                />
              </>
            )}

            {(dataLinks.length > 0 || Object.keys(filtersLinks).length > 0) && (
              <div>
                <FormProvider {...methodsFilters}>
                  <FormStyle>
                    <div className="flex gap-3 overflow-auto border-0 border-b border-solid border-pblack-100 pb-3">
                      <BuyersPopover
                        submitHandler={methodsFilters.handleSubmit(
                          onSubmitFilter,
                        )}
                        buyersList={clients}
                        initialData={clients}
                        dataFiltered={filtersLinks}
                        onCloseHandler={onCloseHandlerFilter}
                      />
                      <PeriodPopover
                        submitHandler={methodsFilters.handleSubmit(
                          onSubmitFilter,
                        )}
                        dataFiltered={filtersLinks}
                        onCloseHandler={onCloseHandlerFilter}
                      />
                    </div>

                    <FilteredListEligibleOrder
                      setDataFilter={setFiltersLinks}
                      handleClick={onSubmitFilter}
                      dataForm={filtersLinks}
                      clients={clients || []}
                      initialData={initialValuesFilters}
                    />
                  </FormStyle>
                </FormProvider>
              </div>
            )}
          </Flex>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer margin="3.6rem auto 9.6rem">
        {dataLinks.length > 0 && canGiveCredit && (
          <>
            <CardGridContainer>
              {dataLinks?.map((link, i) => (
                <ChooseLinkCard
                  editInvoiceDate={editInvoiceDate}
                  link={link}
                  selected={SelectedIds.includes(link.OrderId)}
                  key={i}
                  selectedLink={selectLinkHandler}
                  removedLink={removeLinkHandler}
                />
              ))}
            </CardGridContainer>
            <hr
              style={{
                margin: '3.2rem 0',
                borderColor: `${colors.greyBorder}`,
              }}
            />
            <P bold textAlign="center">
              <Translate id="text.noLinkShow" />
            </P>
          </>
        )}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {modalOpen && (
              <InvoiceDateModalLodable
                buttonTitle="text.okUnderstand"
                modalTitle="lbl.invoiceDate"
                i18n
                link={selectedLink}
                handleClose={() => {
                  methods.reset();
                  setModalOpen(false);
                }}
                submitForm={onSubmit}
              />
            )}
          </form>
        </FormProvider>

        {canGiveCredit &&
        dataLinks.length <= 0 &&
        Object.keys(filtersLinks).length > 0 ? (
          <Flex
            padding="3.2rem 0"
            backgroundStyle="#fff"
            align="center"
            flexDirection="column"
            gap="0.8rem"
            className="rounded-2xl"
          >
            <H5 light>
              <Translate id="lbl.noResults" />
            </H5>
            <P>
              <Translate id="lbl.changeCriteria" />
            </P>
          </Flex>
        ) : (
          <>
            {noLink && (
              <NoLinkMessage
                icon={NoLink}
                textId="text.requestCreditAssignmentNoLink"
                buttonTextId="lbl.createNewPayByLink"
                action={() => {
                  setValue('stateFlow', true);
                  navigate(RoutePath.createLink + RoutePath.chooseProduct);
                }}
                showHelpButton
              />
            )}
            {noValidLink && (
              <NoLinkMessage
                icon={NoLink}
                textId="text.requestCreditAssignmentNoValidLink"
                buttonTextId="lbl.howServiceWorks"
                action={() => navigate(RoutePath.advancePaymentInfo)}
                showHelpButton
              />
            )}
            {!canGiveCredit && (
              <NoLinkMessage
                icon={NoSigned}
                textId="text.requestCreditAssignmentNoSigned"
                buttonTextId="lbl.contactCustomerService"
                action={() => navigate(RoutePath.support)}
              />
            )}
          </>
        )}
      </ResponsiveContainer>
      {dataLinks.length > 0 && canGiveCredit && (
        <ButtonContainerCustom>
          <ResponsiveContainerCustom>
            <Flex justify="center" align="center" gap="0.5rem">
              <div>
                <Icon svgIconComponent={Bill} />
              </div>
              <PSmall colorBlack>
                <Translate
                  id={
                    numLinks === 0
                      ? 'text.advPaymTotalZero'
                      : 'text.advPaymTotal'
                  }
                  data={{
                    total: formatCurrency(amountSelectedLinks),
                    linkNumber: numLinks,
                  }}
                />
              </PSmall>
            </Flex>
            <Button
              disabled={selectedLinks && selectedLinks?.length < 1}
              i18n
              translatedText="lbl.next"
              onClick={() =>
                navigate(RoutePath.advancePaymentRequest + RoutePath.linkDetail)
              }
            />
          </ResponsiveContainerCustom>
        </ButtonContainerCustom>
      )}
    </>
  );
}

export default ChooseLinkPage;
