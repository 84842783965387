import { Resolver } from 'react-hook-form';
import { shippingDateField } from 'src/services/orderLink/orderLink.config';
import required from 'src/utils/validation/required';
import {
  validateFieldSync,
  validateFormSync,
} from 'src/utils/validation/validate-generators';

export const formConfigEditPlanByShippingDate = () => {
  const validate = validateFormSync({
    [shippingDateField]: validateFieldSync(required),
  });

  const resolver: Resolver<any> = async (values: any) => {
    return {
      values: values,
      errors: validate(values),
    };
  };
  return { resolver };
};
