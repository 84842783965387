export const ResetPayByLinkStore = {
  guid: '',
  buyerFiscalCode: '',
  buyerName: '',
  CAP: 0,
  amount: 0,
  description: '',
  inviteEmail: '',
  orderId: 0,
  qrCode: '',
  buyerAddress: '',
  stateFlow: true,
  customInstallments: undefined,
  variantCode: '',
  qrFlow: false,
  flagCredit: false,
  invoiceDate: '',
  payByLinkUrl: '',
  remodulation: false,
  templateSelected: false,
  installmentsPaid: undefined,
  variants: undefined,
  uwId: undefined,
  templates: [],
  template: undefined,
  canPayWithSDD: false,
  VatCode: '',
  country: 'IT',
  postalCode: '',
  firstInstallmentDate: '',
  isDeferred: false,
  results: [],
  editPlanType: undefined,
  shippingDate: undefined,
  editStartINo: undefined,
  installments: [],
};
