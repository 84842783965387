import React, { useEffect } from 'react';
import { INDIGO_TOKEN } from '../../settings';
import { useLanguage } from '../../services/i18n/i18n.context';
import { useAccountStore, useProfileStore } from '../../store/store';
import { COOKIEBOT_GLOBAL_VAR, INDIGO_GLOBAL_VAR } from '../../constants';

interface INDIGO_VARIABLES {
  lang: 'EN' | 'IT';
  user_type: 'MERCHANT' | 'BUYER' | 'GUEST';
  vat_number: string | null;
  company: string | null;
  email: string | null;
  phone: string | null;
}

const IndigoWidget: React.FC<{
  setScriptLoaded: React.Dispatch<React.SetStateAction<any>>;
}> = ({ setScriptLoaded }) => {
  const { language } = useLanguage();
  const {
    AccountType: accountType,
    VatCode: vat_number,
    Name: company,
  } = useProfileStore((state) => state);
  const {
    Email: email,
    PhonePrefix: phonePrefix,
    Phone: phone,
  } = useAccountStore((state) => state);
  const lang = language.substring(0, 2).toUpperCase() || 'IT';

  const setVariables = () => {
    if (!(window as any)[INDIGO_GLOBAL_VAR]) {
      return;
    }

    (window as any)[INDIGO_GLOBAL_VAR]?.setVariables({
      lang,
      user_type: accountType ? accountType : 'GUEST',
      vat_number: vat_number || null,
      company: company || null,
      email: email || null,
      phone:
        phonePrefix && phone
          ? `${(phonePrefix || '').replace(/\D/g, '')}${phone}`
          : phone
            ? phone
            : null,
    } as INDIGO_VARIABLES);
  };

  // FIRST LOADING OF THE WIDGET
  useEffect(() => {
    const loadIndigoWidget = () => {
      const script = document.createElement('script');
      script.src = `https://app.indigo.ai/widget.js?token=${INDIGO_TOKEN}&v=2`;
      script.defer = true;
      script.onload = () => setScriptLoaded(true);

      document.body.appendChild(script);
    };

    // Load the widget only if it's not already loaded
    if (
      !(window as any)[INDIGO_GLOBAL_VAR] &&
      (window as any)[COOKIEBOT_GLOBAL_VAR] &&
      (window as any)[COOKIEBOT_GLOBAL_VAR].consented
    ) {
      loadIndigoWidget();
    } else {
      setScriptLoaded(true);
    }

    document.addEventListener('widget-loaded', () => {
      setVariables();
    });
  }, []);

  useEffect(() => {
    setVariables();
  }, [lang, accountType, vat_number, company, email, phonePrefix, phone]);

  return null;
};

export default IndigoWidget;
