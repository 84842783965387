import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Support } from 'src/images/icons/support.svg';
import { ReactComponent as WaIcon } from 'src/images/icons/whatsapp.svg';
import { useLanguage } from 'src/services/i18n/i18n.context';
import colors from 'src/style-utils/colors';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { COOKIEBOT_GLOBAL_VAR, INDIGO_GLOBAL_VAR } from '../../constants';

const SupportCard1 = ({ handleClick }: any) => {
  return (
    <Card>
      <Icon
        svgIconComponent={Support}
        iconSize="5rem"
        marginBottom="1.2rem"
        color={colors.textColorGrey}
      />
      <PSmall>
        <Translate id="supportPage.CardSubtitle1" />
      </PSmall>
      <H5 marginBottom="0.5rem">
        <Translate id="supportPage.CardTitle1" />
      </H5>
      <P>
        <Translate id="supportPage.CardContent1" />
      </P>
      <Button
        i18n
        translatedText="supportPage.Button1"
        margin="2.4rem auto 0 0"
        onClick={handleClick}
      />
    </Card>
  );
};

const SupportCard1NoCookies = ({ handleClick }: any) => {
  return (
    <Card>
      <Icon
        svgIconComponent={Support}
        iconSize="5rem"
        marginBottom="1.2rem"
        color={colors.textColorGrey}
      />
      <PSmall>
        <Translate id="supportPage.CardSubtitle1" />
      </PSmall>
      <H5 marginBottom="0.5rem">
        <Translate id="supportPage.CardTitle1" />
      </H5>
      <P>
        <Translate id="supportPage.CardContent1NoCookies" />
        &nbsp;
        <Button
          i18n
          translatedText="supportPage.Button1NoCookies"
          variant="LinkPrimary"
          padding="0"
          minWidth="0"
          height="0"
          underline
          onClick={handleClick}
        />
      </P>
    </Card>
  );
};

const SupportCard2 = ({ handleClick }: any) => {
  return (
    <Card>
      <Icon
        svgIconComponent={WaIcon}
        iconSize="5rem"
        marginBottom="1.2rem"
        color={colors.textColorGrey}
      />
      <PSmall>
        <Translate id="supportPage.CardSubtitle2" />
      </PSmall>
      <H5 marginBottom="0.5rem">
        <Translate id="supportPage.CardTitle2" />
      </H5>
      <P>
        <Translate id="supportPage.CardContent2" />
      </P>
      <Button
        i18n
        translatedText="supportPage.Button2"
        margin="2.4rem auto 0 0"
        onClick={handleClick}
      />
    </Card>
  );
};

const SupportCards = () => {
  const canChat =
    typeof (window as any)[INDIGO_GLOBAL_VAR] === 'undefined'
      ? false
      : typeof (window as any)[COOKIEBOT_GLOBAL_VAR] === 'undefined'
        ? false
        : (window as any)[COOKIEBOT_GLOBAL_VAR].consented;
  const { language } = useLanguage();

  const showCookieBanner = () => {
    (window as any)[COOKIEBOT_GLOBAL_VAR]?.show();
    window.addEventListener(
      'CookiebotOnAccept',
      function (e) {
        window.location.reload();
      },
      false,
    );
  };

  const showHumanChat = () => (window as any)[INDIGO_GLOBAL_VAR]?.setOpen(true);

  return (
    <Flex
      data-component="support-cards"
      flexDirection="column"
      gap="2.4rem"
      marginBottom="3em"
    >
      {canChat ? (
        <SupportCard1 handleClick={showHumanChat} />
      ) : (
        <SupportCard1NoCookies handleClick={showCookieBanner} />
      )}
      {language === 'it-IT' && (
        <>
          <SupportCard2
            handleClick={() => {
              window.open(
                'https://api.whatsapp.com/send/?phone=393518708937&text&type=phone_number&app_absent=0',
              );
            }}
          />
        </>
      )}
    </Flex>
  );
};

export default SupportCards;
