import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import Stepper from 'src/components/Stepper/Stepper.component';
import StepperRemodulation from 'src/components/Stepper/StepperRemodulation.component';
import { usePayByLinkStore } from 'src/store/store';

const StepResumeModalBody = () => {
  const {
    customInstallments,
    installmentsPaid,
    remodulation,
    editPlanType,
    editStartINo,
  } = usePayByLinkStore((state) => state);
  return (
    <ModalBody>
      {remodulation && editPlanType === 'default' ? (
        <StepperRemodulation
          dataStepper={installmentsPaid!.concat(
            customInstallments?.Installments!,
          )}
        />
      ) : remodulation && editPlanType === 'shipping_date' ? (
        <StepperRemodulation
          onlyToPay
          numInstallmentToShow={editStartINo}
          dataStepper={customInstallments?.Installments!}
        />
      ) : (
        <Stepper dataStepper={customInstallments?.Installments!} />
      )}
    </ModalBody>
  );
};

export default StepResumeModalBody;
