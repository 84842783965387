import { ReactComponent as Alert } from 'src/images/icons/alert_currentColor.svg';
import { ReactComponent as CheckMark } from 'src/images/icons/feedback-point-checked.svg';
import { ReactComponent as NegativeIcon } from 'src/images/icons/negative feedback_point_black.svg';
import colors from 'src/style-utils/colors';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';

export enum PayByLinkStatus {
  createdLink = 0,
  fullCashed = 1,
  regularIncoming = 2,
  expiredLink = 3,
  expiredPayment = 4, // not regular
  cancelledLink = 5,
  wrongLink = 6,
  unsuitableLink = 7,
  planned = 8,
  interrupted = 9,
  refund = -1,
}

export enum OrderStatus {
  fullCashed = 1,
  regularIncoming = 2,
  expiredPayment = 4,
  planned = 8,
  interrupted = 9,
}

export const IncomeStatusMap = {
  [InstallmentStatus.toPay]: {
    label: 'lbl.incomeToPay',
    icon: undefined,
    color: '',
    borderColor: '',
  },
  [InstallmentStatus.paid]: {
    label: 'lbl.incomePaid',
    icon: CheckMark,
    color: colors.greenStatus,
    borderColor: colors.greenStatus,
  },
  [InstallmentStatus.expired]: {
    label: 'lbl.incomeExpired',
    icon: Alert,
    color: colors.redStatus,
    borderColor: colors.redStatus,
  },
  [InstallmentStatus.cancelled]: {
    label: 'lbl.canceled',
    icon: NegativeIcon,
    color: '',
    borderColor: '',
  },
  [InstallmentStatus.processing]: {
    label: 'lbl.processing',
    icon: undefined,
    color: colors.white,
    borderColor: colors.greenStatus,
  },
};

export const InstallmentStatusMap = {
  [InstallmentStatus.toPay]: {
    label: 'lbl.toPay',
    icon: undefined,
    color: '',
    borderColor: '',
  },
  [InstallmentStatus.paid]: {
    label: 'lbl.paid',
    icon: CheckMark,
    color: colors.greenStatus,
    borderColor: colors.greenStatus,
  },
  [InstallmentStatus.expired]: {
    label: 'lbl.expired',
    icon: Alert,
    color: colors.redStatus,
    borderColor: colors.redStatus,
  },
  [InstallmentStatus.cancelled]: {
    label: 'lbl.canceled',
    icon: NegativeIcon,
    color: '',
    borderColor: '',
  },
  [InstallmentStatus.processing]: {
    label: 'lbl.processing',
    icon: undefined,
    color: colors.white,
    borderColor: colors.greenStatus,
  },
};

{
  /*>>> TO DO: check where used, mapping is not correct*/
}
export const PaymentStatusMap = {
  [PayByLinkStatus.createdLink]: {
    label: 'lbl.toPay',
    icon: undefined,
    color: '',
  },
  [PayByLinkStatus.fullCashed]: {
    label: 'lbl.paid',
    icon: CheckMark,
    color: '',
  },
  [PayByLinkStatus.expiredLink]: {
    label: 'lbl.expired',
    icon: Alert,
    color: '',
  },
  [PayByLinkStatus.cancelledLink]: {
    label: 'lbl.installementcanceled',
    icon: NegativeIcon,
    color: '',
  },
  [PayByLinkStatus.refund]: {
    label: 'lbl.refunded',
    icon: undefined,
    color: '',
  },
  [PayByLinkStatus.regularIncoming]: { label: '', icon: undefined, color: '' },
  [PayByLinkStatus.expiredPayment]: { label: '', icon: undefined, color: '' },
  [PayByLinkStatus.wrongLink]: { label: '', icon: undefined, color: '' },
  [PayByLinkStatus.unsuitableLink]: { label: '', icon: undefined, color: '' },
  [PayByLinkStatus.planned]: { label: '', icon: undefined, color: '' },
  [PayByLinkStatus.interrupted]: {
    label: 'lbl.installementcanceled',
    icon: NegativeIcon,
    color: '',
  },
};

export const PaymentStatusCheckboxMap = {
  [PayByLinkStatus.createdLink]: 'lbl.installmentCreated',
  [PayByLinkStatus.regularIncoming]: 'lbl.installmentPaid',
  [PayByLinkStatus.fullCashed]: 'lbl.installmentPaid',
  [PayByLinkStatus.expiredLink]: 'lbl.installmentExpired',
  [PayByLinkStatus.expiredPayment]: 'lbl.installmentExpired',
  [PayByLinkStatus.refund]: '',
  [PayByLinkStatus.wrongLink]: '',
  [PayByLinkStatus.unsuitableLink]: '',
  [PayByLinkStatus.cancelledLink]: '',
  [PayByLinkStatus.planned]: '',
  [PayByLinkStatus.interrupted]: '',
};

export const PayByLinkStatusSubtitles = {
  [PayByLinkStatus.createdLink]: 'text.flagCreatedDescription',
  [PayByLinkStatus.fullCashed]: 'text.flagCompletedDescription',
  [PayByLinkStatus.regularIncoming]: 'text.flagRegularDescription',
  [PayByLinkStatus.expiredLink]: 'text.flagExpiredDescription',
  [PayByLinkStatus.expiredPayment]: 'text.flagNotRegularDescription',
  [PayByLinkStatus.cancelledLink]: 'text.flagCanceledDescription',
  [PayByLinkStatus.wrongLink]: 'text.flagWrongDescription',
  [PayByLinkStatus.unsuitableLink]: 'text.flagUnsuitableDescription',
  [PayByLinkStatus.refund]: 'text.flagReturnedDescription',
  [PayByLinkStatus.planned]: 'text.flagPlannedDescription',
  [PayByLinkStatus.interrupted]: 'text.flagCanceledDescription',
};

export const PayByLinkStatusLabels = {
  [PayByLinkStatus.createdLink]: 'lbl.flagCreated',
  [PayByLinkStatus.fullCashed]: 'lbl.flagCompleted',
  [PayByLinkStatus.regularIncoming]: 'lbl.flagRegular',
  [PayByLinkStatus.expiredLink]: 'lbl.flagExpired',
  [PayByLinkStatus.expiredPayment]: 'lbl.flagNotRegular',
  [PayByLinkStatus.cancelledLink]: 'lbl.flagCanceled',
  [PayByLinkStatus.wrongLink]: 'lbl.flagWrong',
  [PayByLinkStatus.unsuitableLink]: 'lbl.flagUnsuitable',
  [PayByLinkStatus.refund]: 'lbl.flagReturned',
  [PayByLinkStatus.planned]: 'lbl.flagPlanned',
  [PayByLinkStatus.interrupted]: 'lbl.interruptedPayment',
};

export const PayByLinkStatusMap = {
  [PayByLinkStatus.createdLink]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.createdLink],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.createdLink],
  },
  [PayByLinkStatus.fullCashed]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.fullCashed],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.fullCashed],
  },
  [PayByLinkStatus.planned]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.planned],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.planned],
  },
  [PayByLinkStatus.regularIncoming]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.regularIncoming],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.regularIncoming],
  },
  [PayByLinkStatus.expiredLink]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.expiredLink],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.expiredLink],
  },
  [PayByLinkStatus.expiredPayment]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.expiredPayment],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.expiredPayment],
  },
  [PayByLinkStatus.cancelledLink]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.cancelledLink],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.cancelledLink],
  },
  [PayByLinkStatus.wrongLink]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.wrongLink],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.wrongLink],
  },
  [PayByLinkStatus.unsuitableLink]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.unsuitableLink],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.unsuitableLink],
  },
  [PayByLinkStatus.refund]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.refund],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.refund],
  },
  [PayByLinkStatus.interrupted]: {
    label: PayByLinkStatusLabels[PayByLinkStatus.interrupted],
    subtitle: PayByLinkStatusSubtitles[PayByLinkStatus.interrupted],
  },
};

export const InstallmentsStatusMap = {
  [OrderStatus.fullCashed]: {
    label: 'lbl.completedPayment',
    icon: undefined,
    color: '',
    subtitle: 'text.allInstallmentsPaid',
  },
  [OrderStatus.regularIncoming]: {
    label: 'text.regularPayment',
    icon: undefined,
    color: '',
    subtitle: 'text.regularPayments',
  },
  [OrderStatus.expiredPayment]: {
    label: 'lbl.expiredPayment',
    icon: undefined,
    color: '',
    subtitle: 'text.installmentsHaveNotBeenPaid',
  },
  [OrderStatus.planned]: {
    label: 'lbl.plannedPayment',
    icon: undefined,
    color: '',
    subtitle: 'text.insertPaymentMethod',
  },
  [OrderStatus.interrupted]: {
    label: 'lbl.interruptedPaymentBuyer',
    icon: undefined,
    color: '',
    subtitle: 'text.supplierCancelledPayment',
  },
};
